import React, { useRef } from 'react'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonIcon, ButtonPrimary, ButtonSecondary } from '@components/Button'
import Section from '@components/Section'
import useAnimationIntersectionObserver from '@hooks/useAnimationIntersectionObserver'
import { StyledLink } from '@styles/StyledLink'
import { Body1, Body1Bold, Caption2, Header3, Header4 } from '@typography'
import { getOptionRichText, renderRichText } from '@utils'

import { DivText, ParagraphWithAnyContainer } from './styles'

const ParagraphWithAnySection = ({
  backgroundColor,
  backgroundImage,
  buttons,
  caption,
  children,
  isTextRight,
  isTextTop,
  isUsingSection = true, // To allow using a component with/without the section tag
  link,
  noRowGap,
  // Prevent the animation to fire, can be use when the section is in the first viewport or if it needs to re render
  noAnimation = true,
  paragraph,
  paragraphJson,
  title,
  textPart = undefined,
  ...rest
}) => {
  const ref = useRef()
  const [isAnimating] = useAnimationIntersectionObserver(ref)
  !textPart &&
    (textPart = (
      <DivText>
        {caption && <Caption2>{renderRichText(caption)}</Caption2>}
        {title && title.length <= 90 ? (
          <Header3 as='h2'>{title}</Header3>
        ) : (
          <Header4 as='h2'>{title}</Header4>
        )}
        {paragraphJson ? (
          renderRichText(paragraphJson, getOptionRichText())
        ) : paragraph ? (
          <Body1>{paragraph}</Body1>
        ) : (
          <></>
        )}
        {link && link.label && (
          <StyledLink underline isParagraphLink to={link.link}>
            <Body1Bold>{link.label}</Body1Bold>
          </StyledLink>
        )}
        {buttons &&
          map(
            buttons,
            ({ color, icon, isButtonIcon, label, link, ...rest }, index) =>
              isButtonIcon ? (
                <ButtonIcon
                  key={index + '-button-secondary-paragraph-odd-' + label}
                  color={color}
                  small
                  to={link}
                  icon={icon}
                  {...rest}
                />
              ) : index % 2 ? (
                <ButtonSecondary
                  key={index + '-button-secondary-paragraph-even-' + label}
                  to={link}
                  {...rest}
                >
                  {label}
                </ButtonSecondary>
              ) : (
                <ButtonPrimary
                  key={index + '-button-secondary-paragraph-odd-' + label}
                  color={color}
                  to={link}
                  {...rest}
                >
                  {label}
                </ButtonPrimary>
              ),
          )}
      </DivText>
    ))

  return (
    <Section
      as={!isUsingSection && 'div'}
      backgroundColor={backgroundColor}
      backgroundPicture={backgroundImage}
      {...rest}
    >
      <ParagraphWithAnyContainer
        background={backgroundImage}
        isTextRight={isTextRight}
        isTextTop={isTextTop}
        noRowGap={noRowGap}
        isAnimating={isAnimating}
        noAnimation={noAnimation}
        ref={ref}
      >
        {isTextTop ? (
          <>
            {textPart}
            {children}
          </>
        ) : (
          <>
            {isTextRight ? children : textPart}
            {isTextRight ? textPart : children}
          </>
        )}
      </ParagraphWithAnyContainer>
    </Section>
  )
}

ParagraphWithAnySection.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundColor: PropTypes.string,
  buttons: PropTypes.array,
  caption: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isTextRight: PropTypes.bool,
  isTextTop: PropTypes.bool,
  link: PropTypes.object,
  paragraph: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  paragraphJson: PropTypes.object,
  title: PropTypes.string,
}

export default styled(ParagraphWithAnySection)``
