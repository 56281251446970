import React from 'react'
import { cloneDeep } from 'lodash'
import { Button as ButtonOriginal } from 'component-library/components'
import styled, { css } from 'styled-components'
import { Body2Bold, Body3Bold } from '@typography'
import { baseShadeSecondary } from '@theme'
import getButton from '@theme/Button'
import getColors from '@theme/Colors'
import { sitesConfig } from '@utils/Gatsby-node/siteArchitectureHelpers'

import { withLink } from './withLink'
import { getCommonButtonStyle } from './'

export const ButtonPrimaryStyled = styled(ButtonOriginal)
  // Todo: create a hook to provide a new theme in specific part easily
  .attrs(({ color, theme, ...props }) => {
    if (!color) return { theme, ...props }
    // Supported colors are Mailgun red and Mailjet violet
    if (color) {
      const isMailgun = theme.colors.primary05 === theme.colors.raspberry05
      // Todo: edit getColors to allow passing primary and secondary colors as parameters.
      const brand = isMailgun ? 'mailgun' : 'mailjet'
      const siteConf = sitesConfig[brand]

      const currentSite = siteConf.name
      const colors = getColors(siteConf)

      theme = cloneDeep(theme)
      theme.components.button = getButton({
        colors,
        baseShadePrimary: 50,
        baseShadeSecondary: baseShadeSecondary(currentSite),
      })
    }
    return {
      ...props,
      theme,
    }
  })`
  ${({ noShadow, theme }) => css`
    ${getCommonButtonStyle}

    border: ${theme.sizes.s1} solid ${theme.components.button.primary
      .borderColor};

    ${noShadow
      ? `box-shadow: none;`
      : `box-shadow: 0 ${theme.sizes.s3} ${theme.sizes.s5} -${theme.sizes.s3}
        ${theme.components.button.primary.boxShadow};`};

    &:hover:not(.is-loading):not(.is-disabled):not(:active) {
      border: ${theme.sizes.s1} solid
        ${theme.components.button.primary.borderHoverColor};
    }

    &:active:not(.is-loading) {
      border: ${theme.sizes.s1} solid
        ${theme.components.button.primary.borderPressedColor};
    }

    &:focus:not(:active):not(.is-loading) {
      border: ${theme.sizes.s1} solid transparent;
      ${noShadow
        ? `box-shadow: none;`
        : `box-shadow: 0 ${theme.sizes.s3} ${theme.sizes.s5} -${theme.sizes.s3}
          ${theme.components.button.primary.boxShadow};`};
      outline: ${theme.sizes.s1} solid
        ${theme.components.button.primary.borderFocusColor};
      outline-offset: ${theme.sizes.s1};
    }

    ${Body2Bold}, ${Body3Bold} {
      color: ${theme.colors.buttonPrimaryText};
    }
  `}
`

const ButtonPrimary = ({ children, small, to, ...props }) => (
  <ButtonPrimaryStyled
    aria-label={children && children.toString()}
    mode='primary'
    small={small}
    {...props}
  >
    {small ? (
      <Body3Bold>{children}</Body3Bold>
    ) : (
      <Body2Bold>{children}</Body2Bold>
    )}
  </ButtonPrimaryStyled>
)

export default styled(withLink(ButtonPrimary))``
